<template>
  <div class="content_block">
    <div class="top">
      <b>财务联系人<span v-if="isShowHead">此项信息为非必填，如需维护，请将带<i>*</i>号信息补充完整。</span></b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <template v-if="isEditing">
      <el-form :model="form" ref="JZForm" :rules="rules" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="contactUserName">
                <el-input placeholder="请输入名称" v-model.trim="form.contactUserName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话" prop="contactUserPhone">
              <el-input placeholder="请输入电话" v-model.trim="form.contactUserPhone" maxlength="11" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="contactUserEmail">
              <el-input placeholder="请输入邮箱" v-model.trim="form.contactUserEmail"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所在地区" prop="contactProvinceId">
              <area-cascader
                :provinceId.sync="form.contactProvinceId"
                :provinceName.sync="form.contactProvinceName"
                :cityId.sync="form.contactCityId"
                :cityName.sync="form.contactCityName"
                placeholder="请选择所在地区"
                :areaId.sync="form.contactAreaId"
                :areaName.sync="form.contactAreaName"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="详细地址" prop="contactAddrs">
              <el-input placeholder="请输入详细地址" v-model.trim="form.contactAddrs"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-else>
      <el-descriptions :colon="false" :column="2" style="margin-top: 10px;">
        <el-descriptions-item label="姓名" :span="1" labelClassName="warningLabel" contentClassName="warningContent">{{ form.contactUserName || '' }}</el-descriptions-item>
        <el-descriptions-item label="电话" :span="1" labelClassName="warningLabel" contentClassName="warningContent">{{ form.contactUserPhone || ''  }}</el-descriptions-item>
        <el-descriptions-item label="邮箱" :span="1" labelClassName="warningLabel" contentClassName="warningContent">{{ form.contactUserEmail || '' }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :colon="false" :column="2" >
        <el-descriptions-item label="所在地区" :span="2"  labelClassName="warningLabel" contentClassName="warningContent">{{ form.contactProvinceName }}{{ form.contactCityName }}{{ form.contactAreaName }}</el-descriptions-item>
        <el-descriptions-item label="详细地址" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
          {{ form.contactAddrs || '' }}
        </el-descriptions-item>
      </el-descriptions>
    </template>
  </div>
</template>

<script>
import AreaCascader from '@/components/common/areaCascader';
export default {
  name: '',
  components: {
    AreaCascader,
  },
  props: {
    concatLists: {
      type: Array,
      default () {
        return [];
      },
    },
    type: {
      type: String,
      default: '2',
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    activeName: {
      handler (val) {
        if (val === 'FinancialContact') {
          let info = this.concatLists.find(i=>i.contactType === this.type);
          info = info ? info = info ? JSON.parse(JSON.stringify(info)) : null : null;
          this.form = info ? {
            ...info,
            operateType: '2',
            contactType: this.type,
          } : {
            contactUserName: '',
            contactUserPhone: '',
            contactUserEmail: '',
            contactProvinceId: '',
            contactProvinceName: '',
            contactCityId: '',
            contactCityName: '',
            contactAreaId: '',
            contactAreaName: '',
            contactAddrs: '',
            operateType: '1',
            contactType: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    concatLists: {
      handler (val) {
        if (val) {
          let info = val.find(i=>i.contactType === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info ? {
            ...info,
            operateType: '2',
            contactType: this.type,
          } : {
            contactUserName: '',
            contactUserPhone: '',
            contactUserEmail: '',
            contactProvinceId: '',
            contactProvinceName: '',
            contactCityId: '',
            contactCityName: '',
            contactAreaId: '',
            contactAreaName: '',
            contactAddrs: '',
            operateType: '1',
            contactType: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clearValidate () {
      this.$nextTick(()=>{
        this.$refs.JZForm && this.$refs.JZForm.clearValidate();
      });
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      let info = this.concatLists.find(i=>i.contactType === this.type);
      info = info ? JSON.parse(JSON.stringify(info)) : null;
      this.form = info ? {
        ...info,
        operateType: '2',
        contactType: this.type,
      } : {
        contactUserName: '',
        contactUserPhone: '',
        contactUserEmail: '',
        contactProvinceId: '',
        contactProvinceName: '',
        contactCityId: '',
        contactCityName: '',
        contactAreaId: '',
        contactAreaName: '',
        contactAddrs: '',
        operateType: '1',
        contactType: this.type,
      };
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            contactType: this.type,
          }));
          this.$emit('updateContact', {...this.form, tenantFileReqDTOs}, this.type);
          this.isEditing = false;
        }
      });
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            contactType: this.type,
          }));
          this.$emit('updateContact', {...this.form, tenantFileReqDTOs}, this.type);
        }
      });
    },
  },
  data () {
    return {
      isEditing: false,
      form: {
        contactUserName: '',
        contactUserPhone: '',
        contactUserEmail: '',
        contactProvinceId: '',
        contactProvinceName: '',
        contactCityId: '',
        contactCityName: '',
        contactAreaId: '',
        contactAreaName: '',
        contactAddrs: '',
        operateType: '1',
        contactType: this.type,
      },
      rules: {
        contactUserName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
        contactUserPhone: [
          {
            required: true,
            message: '请输入电话',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^1\d{10}$/,
            message: '电话格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
        contactUserEmail: [
          {
            pattern: /^(?=.*@)(?=.*\.)[^@.].*[^@.]$/,
            message: '请输入合法的邮箱',
            trigger: ['blur'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    b>span{
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgb(95, 106, 122);
      letter-spacing: 0px;
      margin-left: 10px;
      font-weight: normal;
      i{
        font-style: normal;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #FF5959;
        letter-spacing: 0;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 100px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
}
</style>
